import React, { useRef, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import SearchableSelect from "../SearchableSelect";
import Extension from "./Extension";
import ConsumableAddons from "./ConsumableAddons";

const ConsumableItemsModal = ({ show, toggle, close, data }) => {
  const consumableRef = useRef();
  const [addons, setAddons] = useState({
    marketplace_items_limit: {
      quantity: "",
      whole_price: 0,
    },
    emails_limit: {
      quantity: "",
      whole_price: 0,
    },
    sms_limit: {
      quantity: "",
      whole_price: 0,
    },
  });
  const handleClick = (e) => {
    try {
      consumableRef.current.addRequest(e);
    } catch (error) {}
  };
  const hideModal = () => {
    setAddons({
      marketplace_items_limit: {
        quantity: "",
        whole_price: 0,
      },
      emails_limit: {
        quantity: "",
        whole_price: 0,
      },
      sms_limit: {
        quantity: "",
        whole_price: 0,
      },
    });
    close();
  };
  function validateLimits(limits) {
    return Object.values(limits).some((limit) => limit.quantity !== "");
  }
  return (
    <>
      <Modal size="lg" show={show} onHide={hideModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Consumable Items Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ConsumableAddons ref={consumableRef} data={data} close={hideModal} toggle={toggle} addons={addons} setAddons={setAddons} />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end mt-4">
            <Button disabled={!validateLimits(addons)} onClick={handleClick}>
              {"Submit"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConsumableItemsModal;
