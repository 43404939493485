import React, { useState, useEffect, useCallback, useRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import PaginationBar from "../../../Components/PaginationBar/PaginationBar";

import * as api from "./../../../api/getapi";
import useApi from "../../../Hooks/useApi";
import customloader from "./../../../assets/images/RollingLoader.gif";
import NoResult from "./../../../Components/NoResult/NoResult";

import CustomizeTableview from "../../../Components/CustomizedTable/CustomizedTable";
import PopUpModal from "../../../Components/Modal";
import ResendEmail from "../ResendEmail/ResendEmail";
import Transaction from "../Transaction/Transaction";
import EditShop from "../../EditShop";
import { ToastContainer } from "react-toastify";
import { dateTime } from "../../../Features/dateTime";
import ShiftToPaidPlanModal from "../../../Components/Subscription/ShiftToPaidPlanModal";
import { useReactToPrint } from "react-to-print";
import PaymentInvoice from "../../../Components/PaymentInvoice";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ExtensionModal from "../../../Components/Addons/ExtensionModal";
import ConsumableItemsModal from "../../../Components/Addons/ConsumableItemsModal";

const DELAY_TIME = 100;

function ShopkeeperOnPayment({ province, city, salesman, search, status, setOrderBy, sizeOfPage, orderBy, activeTab }) {
  const componentRef = useRef(null);
  let [items, setItems] = useState([]);
  let [isLoading, setIsLoading] = useState([]);
  const [noOfPages, setNoOfPages] = useState(1);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, toggle] = useState(false);
  const [shopId, setShopId] = useState();
  const [emailModal, setEmailModal] = useState(false);
  const getshopkeepersOnPay = useApi(api.getShopKeepersOnPayment);
  const [transactionModal, setTransactionModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [paidPlanModal, setPaidPlanModal] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [printId, setPrintId] = useState();
  const [extensionModal, setExtensionModal] = useState(false);
  const [consumableItemModal, setConsumableItemModal] = useState(false);
  const [shopData, setShopData] = useState(null);

  const fetchData = async () => {
    const res = await getshopkeepersOnPay.request({
      province: province?.value,
      city: city?.value,
      salesman: salesman,
      search: search,
      orderBy: orderBy,
      size: sizeOfPage,
      isActive: status.value,
      page: currentPage,
    });
    const data = res.data;
    const paymentData = data.data.map((data1) => {
      const { salesman, ...arr } = data1;
      return arr;
    });
    setCount(data.counts.no_of_items);
    if (data.counts.no_of_pages === 0) {
      setNoOfPages(0);
    } else {
      setNoOfPages(data.counts.no_of_pages);
      if (currentPage > data.counts.no_of_pages) {
        setCurrentPage(data.counts.no_of_pages);
      }
    }
    setIsLoading(false);
    setItems(paymentData);
  };
  useEffect(() => {
    const pageTimeout = setTimeout(async () => {
      fetchData();
    }, DELAY_TIME);
    return () => {
      clearTimeout(pageTimeout);
    };
  }, [province, city, salesman, search, orderBy, emailModal, currentPage, refresh, sizeOfPage]);
  useEffect(() => {
    setIsLoading(true);
    setCurrentPage(1);
    fetchData();
  }, [status]);
  const edit = (id) => {
    setShopId(id);
    setShowEditModal(true);
  };
  const sendEmail = (id) => {
    setShopId(id);
    setEmailModal(true);
  };
  const changePage = (page) => {
    setCurrentPage(page);
  };
  const handleTransactions = (id) => {
    setShopId(id);
    setTransactionModal(true);
  };
  const handleActivateSubscription = (id) => {
    setShopId(id);
    setPaidPlanModal(true);
  };

  const handleOnBeforeGetContent = () => {
    setIsPrinting(true);
  };
  const handleBeforePrint = () => {
    setIsPrinting(true);
  };

  const handleAfterPrint = () => {
    setIsPrinting(false);
    setPrintId(-1);
  };
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Payment Invoice",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
  });
  const hanldeId = (id) => {
    setPrintId(id);
  };

  const InvoicePayments = async (id) => {
    window.open(`/shopkeepers/payment-invoice/` + id, "_blank", "noopener,noreferrer");
  };
  const handleSaveAsPDF = (id) => {
    const input = componentRef.current;
    if (input) {
      html2canvas(input)
        .then((canvas) => {
          const pdf = new jsPDF("p", "mm", "a4");
          const imgData = canvas.toDataURL("image/png");
          const imgWidth = 210; // A4 width in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width; // maintain aspect ratio
          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight, undefined, "FAST");
          pdf.save(`${id}_invoice.pdf`);
          setPrintId(-1);
        })
        .catch((error) => {
          setPrintId(-1);
          console.error("Error generating PDF: ", error);
        });
    }
  };
  const handleExtensionAddons = async (data) => {
    setExtensionModal(true);
    setShopData(data);
  };

  const handleConsumableItemsAddons = async (data) => {
    setConsumableItemModal(true);
    setShopData(data);
  };
  return (
    <>
      <ToastContainer />
      <div style={{ position: "absolute", top: "-9999px", left: "-9999px" }}>
        <PaymentInvoice printId={printId} ref={componentRef} />
      </div>
      <PopUpModal title="Resend Email" show={emailModal} onHide={() => setEmailModal(false)}>
        <ResendEmail shopId={shopId} close={() => setEmailModal(false)} />
      </PopUpModal>
      <PopUpModal size="md" title={status?.value === true ? "Renew Plan" : status?.value === "awaiting" ? "Log Transaction" : "Activate Shop"} show={transactionModal} onHide={() => setTransactionModal(false)}>
        <Transaction close={() => setTransactionModal(false)} toggle={toggle} id={shopId} nextPlan={status?.value === "awaiting" ? false : true} />
      </PopUpModal>
      <PopUpModal size="md" title="Shift Plan" show={paidPlanModal} onHide={() => setPaidPlanModal(false)}>
        <ShiftToPaidPlanModal close={() => setPaidPlanModal(false)} toggle={toggle} id={shopId} />
      </PopUpModal>
      <PopUpModal size="lg" title="Edit Shop" show={showEditModal} onHide={() => setShowEditModal(false)}>
        <EditShop shopId={shopId} close={() => setShowEditModal(false)} toggle={toggle} />
      </PopUpModal>
      <ExtensionModal
        toggle={toggle}
        close={() => {
          setExtensionModal(false);
          setShopData(null);
        }}
        show={extensionModal}
        data={shopData}
      />
      <ConsumableItemsModal
        toggle={toggle}
        close={() => {
          setConsumableItemModal(false);
          setShopData(null);
        }}
        show={consumableItemModal}
        data={shopData}
      />
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
          <img src={customloader} alt="customloader" width="100" height="100" />
        </div>
      ) : !items[0] ? (
        <NoResult path={"/register-shop"} name="Shop" />
      ) : (
        <>
          <CustomizeTableview
            data={items}
            columnNotShow={["is_active", "grace_period_ends_on", "banner_showing_start_date", "is_subscription_cancelled", "shop_subscription_status", "subscription_status", "cancelled_on", "current_plan_billing_frequency", "subscription_type"]}
            edit={edit}
            email={sendEmail}
            sortColumn={["shop_name", "address", "email", "whatsapp", "pos_monthly_charges", "marketplace_monthly_charges", "salesman", "current_plan_start_date", "current_plan_end_date"]}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            dateColumn={["current_plan_end_date", "current_plan_start_date"]}
            print={{
              action: (id) => {
                hanldeId(id);
                setTimeout(() => handlePrint(), 1000);
              },
              column: ["is_active"],
              isShow: (value) => {
                if (Number(value["is_active"]) === 1 || Number(value["is_active"]) === 0) {
                  return true;
                } else {
                  return false;
                }
              },
            }}
            view={{ action: InvoicePayments, column: ["is_active"], isShow: (value) => Number(value["is_active"]) === 1 || Number(value["is_active"]) === 0 }}
            download={{
              action: (id) => {
                hanldeId(id);
                setTimeout(() => handleSaveAsPDF(id), 1000);
              },
              column: ["is_active"],
              isShow: (value) => Number(value["is_active"]) === 1 || Number(value["is_active"]) === 0,
            }}
            actionButton={{
              label: status?.value === true ? "Renew Plan" : status?.value === "awaiting" ? "Log Transaction" : "Activate Subscription",
              onClick: (id) => {
                status?.value === true ? handleTransactions(id) : status?.value === "awaiting" ? handleTransactions(id) : handleActivateSubscription(id);
              },
              variant: "outline-primary",
              style: { padding: "8px", fontSize: "12px" },
              column: ["subscription_status", "grace_period_ends_on", "banner_showing_start_date", "is_active", "is_subscription_cancelled", "cancelled_on"],
              isShow: (value) => {
                if (Number(value["subscription_status"]) === 0) {
                  return true;
                } else if (Number(value["is_active"]) === 0) {
                  return true;
                } else if ((Number(value["is_subscription_cancelled"]) === 0 && value["cancelled_on"] !== null) || (Number(value["is_subscription_cancelled"]) === 1 && value["cancelled_on"] === null) || (Number(value["is_subscription_cancelled"]) === 1 && value["cancelled_on"] !== null)) {
                  return false;
                } else {
                  const gracePeriod = dateTime(value["grace_period_ends_on"]);
                  const bannerShowing = dateTime(value["banner_showing_start_date"]);
                  // between grace period and banner showing start date check
                  if (bannerShowing <= new Date() && gracePeriod >= new Date()) {
                    return true;
                  } else {
                    return false;
                  }
                }
              },
            }}
            actionButtons={[
              {
                label: "Expand Plan Request",
                onClick: (row) => {
                  handleExtensionAddons(row);
                },
                variant: "outline-primary",
                style: { padding: "8px", fontSize: "12px" },
                isShow: (value) => {
                  return true;
                },
                disabled: (value) => {
                  return (Number(value["is_subscription_cancelled"]) === 0 && value["cancelled_on"] !== null) || (Number(value["is_subscription_cancelled"]) === 1 && value["cancelled_on"] === null) || (Number(value["is_subscription_cancelled"]) === 1 && value["cancelled_on"] !== null)
                    ? true
                    : dateTime(value["banner_showing_start_date"]) <= new Date() && dateTime(value["grace_period_ends_on"]) >= new Date()
                    ? false
                    : true;
                },
              },
              {
                label: "Consumable Items Request",
                onClick: (row) => {
                  handleConsumableItemsAddons(row);
                },
                variant: "outline-primary",
                style: { padding: "8px", fontSize: "12px" },
                isShow: (value) => {
                  return true;
                },
              },
            ]}
          />
          <Row className="mt-5">
            <Col className="d-none d-lg-block" md="6" lg="3">
              <span className="text-secondary fs-6">{count} results</span>
            </Col>
            <Col xs="12" lg="6">
              <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
            </Col>
            <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{count} Result </span>
            </Col>
            <Col xs="6" lg="3" className="text-end mt-3"></Col>
          </Row>
        </>
      )}
    </>
  );
}

export default ShopkeeperOnPayment;
