import React, { useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Extension from "./Extension";

const ExtensionModal = ({ show, toggle, close, data }) => {
  const extensionRef = useRef();
  const [clicked, setClicked] = useState(false);
  const [selected, setSelected] = useState(null);
  const handleClick = (e) => {
    try {
      if (clicked) {
        extensionRef.current.addRequest(e);
      } else {
        setClicked(true);
      }
    } catch (error) {}
  };
  const hideModal = () => {
    setClicked(false);
    setSelected(null);

    close();
  };
  function validateLimits(limits) {
    return Object.values(limits).some((limit) => limit.quantity !== "");
  }
  return (
    <>
      <Modal size="lg" show={show} onHide={hideModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Plan Extension Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Extension ref={extensionRef} data={data} clicked={clicked} setClicked={setClicked} selected={selected} setSelected={setSelected} close={hideModal} toggle={toggle} />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end mt-4">
            <Button disabled={!selected} onClick={handleClick}>
              {!clicked ? "Continue" : "Submit"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExtensionModal;
